import store from '@/store';
import { Language } from '@/modules/parameters/Language';

export interface ContentModel {
	language: string;
	name: string,
}

export const getValueInUserLanguage = (content: ContentModel[], prop = 'name'): string | undefined => {
	const foundContent = content.find(c => c.language === store.getters.userLanguage);

	if (!foundContent) {
		return undefined;
	}

	return foundContent[prop];
};

export const fillContentModels = (
	collection: ContentModel[],
	languages: Language[],
	shape: Record<string, string> = {name: ''},
) => {
	const map: Record<string, ContentModel> = {};

	collection.forEach(model => {
		map[model.language] = model;
	});

	languages.forEach(language => {
		if (!map[language.id]) {
			map[language.id] = {
				language: language.id,
				...shape,
			} as ContentModel;
		}
	});

	return map;
};
