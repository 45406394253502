type Subscriber = { unsubscribe: () => void };
type SubscriberFunc = (payload) => void;
const subscribers: Record<string, Record<string, SubscriberFunc>> = {};

/**
 * Add a new global event listener
 */
export const subscribe = (eventName: string, callback: SubscriberFunc): Subscriber => {
	const id = Math.random();

	if (!subscribers[eventName]) {
		subscribers[eventName] = {};
	}
	subscribers[eventName][id] = callback;

	return {
		unsubscribe() {
			delete subscribers[eventName][id];
		},
	};
};

export function triggerEvent(eventName: string, payload) {
	if (eventName in subscribers) {
		Object.values(subscribers[eventName]).forEach(callback => callback(payload));
	}
}
