export default (element: HTMLElement) => {
	if (
		element.tabIndex > 0
		|| (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)
	) {
		return true;
	}

	if ((element as HTMLInputElement).disabled) {
		return false;
	}

	switch (element.nodeName) {
		case 'A':
			return (!!(element as HTMLAnchorElement).href && (element as HTMLAnchorElement).rel !== 'ignore');
		case 'INPUT':
			return ((element as HTMLInputElement).type !== 'hidden');
		case 'BUTTON':
		case 'SELECT':
		case 'TEXTAREA':
			return true;
	}

	return false;
};
