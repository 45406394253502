import { LinkBuilder } from '@/core/common';
import { RouteLocationRaw } from 'vue-router';

type ColumnFormatter<T> = (row: T) => string;

export default class Column<T> {
	public readonly label: string;
	public readonly field: keyof T;
	public readonly sortField: string | null;
	public sortOrder: string | null;
	public formatter: ColumnFormatter<T> | null = null;
	public width: string | null = null;
	public classList: string[] = [];
	public component: string | null = null;
	private linkBuilder: LinkBuilder<T> | null = null;
	private nestedField: string | null = null;

	constructor(
		label: string,
		field: keyof T,
		sortField: string | null = null,
		sortOrder: string | null = null,
		classList: string[] = [],
	) {
		this.field = field;
		this.label = label;
		this.sortField = sortField;
		this.sortOrder = sortOrder;
		this.classList = classList;
		this.configure();
	}

	protected configure() {
		// Configuration done in sub classes to not have to rewrite the constructor every time
	}

	public withFormatter(formatter: ColumnFormatter<T>): Column<T> {
		this.formatter = formatter;

		return this;
	}

	public withWidth(width: string): Column<T> {
		if (width.substr(-1) !== '%' && width.substr(-2) !== 'px') {
			throw new Error(`Invalid "width" for column "${this.label}". Argument must end with "%" or "px".`);
		}

		this.width = width;

		return this;
	}

	public withNestedField(field: string): Column<T> {
		this.nestedField = field;

		return this;
	}

	public withClass(className: string): Column<T> {
		this.classList.push(className);

		return this;
	}

	public withClassList(classList: string[]): Column<T> {
		this.classList = classList;

		return this;
	}

	public asLink(linkBuilder: LinkBuilder<T>): Column<T> {
		this.component = 'LinkColumn';
		this.linkBuilder = linkBuilder;

		return this;
	}

	public getTo(row: T): RouteLocationRaw | null {
		if (this.linkBuilder) {
			return this.linkBuilder(row);
		}

		return null;
	}

	public render(row: T): unknown {
		if (this.formatter) {
			return this.formatter(row);
		}

		const value = row[this.field];

		if (this.nestedField && value && typeof value === 'object') {
			return value[this.nestedField];
		}

		return value;
	}

	public toggleSort() {
		if (this.sortOrder === null) {
			this.sortOrder = '+';
		} else if (this.sortOrder === '+') {
			this.sortOrder = '-';
		} else {
			this.sortOrder = '+';
		}
	}
}
