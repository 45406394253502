import isFocusable from '@/core/isFocusable';
import store from '@/store';

export type Modal = {
	el: HTMLDivElement;
	lastFocus: Node | null;
}

const stack: Modal[] = [];
let ignoreFocus = false;
let focusTrapInitiated = false;

const focusFirstDescendant = (dialog: HTMLElement) => {
	let i;
	let child;

	for (i = 0; i < dialog.childNodes.length; i++) {
		child = dialog.childNodes[i];
		if (attemptFocus(child) || focusFirstDescendant(child)) {
			return true;
		}
	}

	return false;
};

const focusLastDescendant = (modal: HTMLElement) => {
	let i;
	let child;

	for (i = modal.childNodes.length - 1; i >= 0; i--) {
		child = modal.childNodes[i];
		if (attemptFocus(child) || focusLastDescendant(child)) {
			return true;
		}
	}

	return false;
};

const attemptFocus = (element: HTMLElement) => {
	if (!isFocusable(element)) {
		return false;
	}

	ignoreFocus = true;

	try {
		element.focus();
	} catch (e) {
		// Do nothing
	}

	ignoreFocus = false;

	return (document.activeElement === element);
};

const trapFocusListener = (e: FocusEvent) => {
	if (!stack.length || ignoreFocus || e.target === document) {
		return;
	}

	const currentDialog = stack[stack.length - 1];
	const currentDialogContent = currentDialog.el.querySelector('.modal') as HTMLElement;

	if (!currentDialogContent) {
		return;
	}

	if (e.target instanceof Node && currentDialogContent.contains(e.target)) {
		currentDialog.lastFocus = e.target;
	} else {
		focusFirstDescendant(currentDialogContent);
		if (currentDialog.lastFocus === document.activeElement) {
			focusLastDescendant(currentDialogContent);
		}

		currentDialog.lastFocus = document.activeElement;
	}
};

export default {
	addToStack(modal: HTMLDivElement) {
		const modalModel = {
			el: modal,
			lastFocus: null,
		};

		stack.push(modalModel);
		store.commit('addModal', modalModel);

		if (!focusTrapInitiated) {
			addEventListener('focus', trapFocusListener, true);
			focusTrapInitiated = true;
		}
	},
	popStack() {
		const poppedModal = stack.pop();

		if (poppedModal) {
			store.commit('removeModal', poppedModal);
		}
	},
};
